// preload moment locales
import 'moment/locale/de';
import { AppConfig } from '@unifly-aero/web-common/src/config/AppConfig';
import CommonAppConfig from '@unifly-aero/web-common/src/config/AppConfiguration';

const AppConfiguration: AppConfig = {
    ...CommonAppConfig,
    i18n: {
        ...CommonAppConfig.i18n,
        locales: ['es', 'en'],
        additionalNamespaces: ['proj'],
        defaultOverrideNamespace: 'proj',
    },
    defaultZoomLocation: [-9.68994140625, 35.90684930677121, 4.85595703125, 42.391008609205045],
    countryCodeISO2: 'ES',
    allowedTimeZoneOffsets: ['Europe/Madrid', 'Etc/UTC'],
    showOperatorRegistration: true,
    showUserManualLink: false,
    dataDashboard: {
        colors: [
            '#00224c',
            '#009fda',
            '#0053b8',
            '#24c4ff',
            '#2687ff',
            '#6dd7ff',
            '#92c3ff',
            '#b6ebff'
        ],
        pieChart: {
            legendIcon: 'rect'
        },
        visiblePieCharts: [
            'OPERATION_DURATION_AVG'
        ],
        visibleBarCharts: [
            'ACTION_ITEM_DECISION_COUNT',
            'REGISTERED_OPERATOR_ACCOUNTS_COUNT',
            'REGISTERED_USERS_COUNT',
            'REGISTERED_UAS_COUNT',
            'OPERATION_COUNT',
            'FLIGHT_COUNT'
        ],
        visibleTableCharts: [
            'FLIGHT_HOURS_TOTAL'
        ]
    }
};

export default AppConfiguration;
