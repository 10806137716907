import '../../proj-common/src/styles/theme.scss';

import { Aurelia } from 'aurelia-framework';
import { bootstrap } from 'aurelia-bootstrapper';
import { configure as configureSentry, start as startSentry } from '@unifly-aero/web-sentry/src/main';
import AppConfiguration from './config/AppConfiguration';

export function configure(aurelia: Aurelia) {
    configureSentry(aurelia, AppConfiguration);
    startSentry(aurelia);
}

bootstrap(configure);
